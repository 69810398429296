import { Box } from "@mui/material";
import clsx from "clsx";
import styles from "./BrandLoader.module.scss";
import LogoPath from "assets/logo.svg";
import Image from "next/image";

const BrandLoader = () => {
  return (
    <Box className={styles.root}>
      <Box
        className={clsx(styles.logo, "animate__animated", "animate__fadeIn")}
      >
        <Image src={LogoPath} alt="logo" width={150} height={150} />
      </Box>
    </Box>
  );
};

export default BrandLoader;
