import { TEditProfileForm } from "@types";
import httpClient from "services/http.service";

export const updateProfileAPI = async (data: TEditProfileForm) => {
  return await httpClient.patch("users/me/", data);
};

export const setPasswordAPI = async (
  current_password: string,
  new_password: string
) => {
  return await httpClient.post("users/password/set/", {
    current_password,
    new_password,
  });
};

export const getTeacherStats = async () => {
  const res = await httpClient.get("users/me/teacher_stats/");
  return res.data;
};
