import {
  IGetLessonDetailAPIParams,
  ICreateLessonAPIParams,
  IDeleteLessonAPIParams,
  IUpdateLessonAPIParams,
} from "@types";
import httpClient from "services/http.service";

export const getLessonDetailAPI = async (params: IGetLessonDetailAPIParams) => {
  const { courseId, moduleId, lessonId } = params;
  return await httpClient.get(
    `courses/${courseId}/modules/${moduleId}/lessons/${lessonId}/`
  );
};

export const createLessonAPI = async (data: ICreateLessonAPIParams) => {
  const { courseId, moduleId } = data;
  return await httpClient.post(
    `courses/${courseId}/modules/${moduleId}/lessons/`,
    { module: moduleId }
  );
};

export const updateLessonAPI = async (body: IUpdateLessonAPIParams) => {
  const { courseId, moduleId, lessonId, data } = body;
  return await httpClient.patch(
    `courses/${courseId}/modules/${moduleId}/lessons/${lessonId}/`,
    data
  );
};

export const deleteLessonAPI = async (params: IDeleteLessonAPIParams) => {
  const { courseId, moduleId, lessonId } = params;
  return await httpClient.delete(
    `courses/${courseId}/modules/${moduleId}/lessons/${lessonId}/`
  );
};
