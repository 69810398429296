import {
  ICreateModuleAPIParams,
  IDeleteModuleAPIParams,
  IUpdateModuleAPIParams,
} from "@types";
import httpClient from "services/http.service";

export const getModuleListAPI = async (courseId: number) => {
  return await httpClient.get(`/courses/${courseId}/modules/`);
};

export const createModuleAPI = async (data: ICreateModuleAPIParams) => {
  const { courseId, order } = data;
  return await httpClient.post(`courses/${courseId}/modules/`, { order });
};

export const updateModuleAPI = async (body: IUpdateModuleAPIParams) => {
  const { courseId, moduleId, data } = body;
  return await httpClient.patch(
    `courses/${courseId}/modules/${moduleId}/`,
    data
  );
};

export const deleteModuleAPI = async (data: IDeleteModuleAPIParams) => {
  const { courseId, moduleId } = data;
  return await httpClient.delete(`courses/${courseId}/modules/${moduleId}/`);
};
