import { createTheme } from "@mui/material";

const defaultTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: "#27AE60",
    },
    text: {
      primary: "#333333",
    },
  },
  typography: {
    fontFamily: "'Inter', sans-serif",
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        // @ts-ignore
        root: {
          borderRadius: 8,
          color: "#828282",
        },
        select: {
          padding: 12,
          fontSize: 18,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          backgroundColor: "#27AE60",
          color: "white",

          "&:hover": {
            backgroundColor: "#27AE60",
            color: "white",
          },
          "&:disabled": {
            backgroundColor: "#F2F2F2",
            color: "#828282",
          },
        },
      },
    },
  },
});

export { defaultTheme };
