import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser, TRole } from "@types";
import { AuthState } from "./state";
import { getUserDataThunk, updateProfileThunk } from "./thunk";

const authSlice = createSlice({
  name: "auth",
  initialState: AuthState,
  reducers: {
    setRegistrationStep: (
      state,
      action: PayloadAction<"select_role" | "form" | "confirmation">
    ) => {
      state.registration.step = action.payload;
    },
    setRegistrationRole: (state, action: PayloadAction<TRole>) => {
      state.registration.role = action.payload;
    },
    setRegistrationEmail: (state, action: PayloadAction<string>) => {
      state.registration.email = action.payload;
    },
    resetRegistrationState: (state) => {
      state.registration = {
        step: "select_role",
        role: null,
        email: "",
      };
    },
    logout: (state) => {
      state.user = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(
      getUserDataThunk.fulfilled,
      (state, action: PayloadAction<IUser>) => {
        state.user = action.payload;
      }
    );
    builder.addCase(
      updateProfileThunk.fulfilled,
      (state, action: PayloadAction<IUser>) => {
        state.user = action.payload;
      }
    );
  },
});

export const {
  setRegistrationStep,
  setRegistrationRole,
  setRegistrationEmail,
  resetRegistrationState,
  logout,
} = authSlice.actions;

export default authSlice;
