import { IUser, TRole } from "@types";

interface IAuthState {
  registration: {
    step: "select_role" | "form" | "confirmation";
    role: TRole;
    email: string;
  };
  user: IUser | null;
}

export const AuthState: IAuthState = {
  registration: {
    step: "select_role",
    role: null,
    email: "",
  },
  user: null,
};
