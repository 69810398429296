import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import type { AppProps } from "next/app";
import { defaultTheme } from "../styles/theme";
import "styles/index.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "nprogress/nprogress.css";
import Router from "next/router";
import NProgress from "nprogress";
import { useEffect, useState } from "react";
import { StyledEngineProvider } from "@mui/material/styles";
import { appWithTranslation } from "next-i18next";
import { storeWrapper } from "store";
import { SessionProvider } from "next-auth/react";
import { SnackbarProvider } from "notistack";
import "animate.css";
import Auth from "containers/Auth";
import "styles/ckeditor.scss";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

function MyApp({ Component, pageProps: { session, ...pageProps } }: AppProps) {
  useEffect(() => {
    const handleRouteStart = () => NProgress.start();
    const handleRouteDone = () => NProgress.done();

    Router.events.on("routeChangeStart", handleRouteStart);
    Router.events.on("routeChangeComplete", handleRouteDone);
    Router.events.on("routeChangeError", handleRouteDone);

    return () => {
      Router.events.off("routeChangeStart", handleRouteStart);
      Router.events.off("routeChangeComplete", handleRouteDone);
      Router.events.off("routeChangeError", handleRouteDone);
    };
  }, []);

  return (
    <SessionProvider session={session}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={defaultTheme}>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              horizontal: "center",
              vertical: "bottom",
            }}
          >
            <CssBaseline />
            {/* @ts-ignore */}
            {Component.auth ? (
              <Auth>
                <Component {...pageProps} />
              </Auth>
            ) : (
              <Component {...pageProps} />
            )}
          </SnackbarProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </SessionProvider>
  );
}

export default storeWrapper.withRedux(appWithTranslation(MyApp));
