import {
  IGetCourseListAPIParams,
  IUpdateCourseAPIParams,
  TCreateCourseAPIParams,
} from "@types";
import httpClient from "services/http.service";

export const getCourseListAPI = async (params?: IGetCourseListAPIParams) => {
  const res = await httpClient.get("courses/", { params });
  return res.data;
};

export const getCourseDetailAPI = async (id: number) => {
  const res = await httpClient.get(`courses/${id}/`);
  return res.data;
};

export const createCourseAPI = async (data: TCreateCourseAPIParams) => {
  return await httpClient.post("courses/", data);
};

export const updateCourseAPI = async (body: IUpdateCourseAPIParams) => {
  const { courseId, data } = body;
  return await httpClient.patch(`courses/${courseId}/`, data);
};

export const getAuthorCourseListAPI = async () => {
  const res = await httpClient.get("courses/author/");
  return res.data;
};

/**
 * API покупки курса
 * https://docs.ioka.kz/basics/quickstart/
 * @param courseId
 * @returns
 */
export const buyCourseAPI = async (courseId: number) => {
  const res = await httpClient.post<{ order_id: string }>(
    "payments/orders/",
    { course_id: courseId },
    {
      timeout: 30_000,
    }
  );
  return res.data;
};
