import {
  ICreateAccountAPIParams,
  ILoginAPIParams,
  IResetPasswordAPIParams,
} from "@types";
import { AxiosRequestConfig } from "axios";
import httpClient from "services/http.service";

export const createAccountAPI = async (data: ICreateAccountAPIParams) => {
  return await httpClient.post("users/", data);
};

export const loginAPI = async (data: ILoginAPIParams) => {
  const res = await httpClient.post<{ access: string; refresh: string }>(
    "auth/login/",
    data,
    {
      headers: {
        Authorization: "",
      },
    }
  );
  return res.data;
};

export const refreshTokenAPI = async (refreshToken: string) => {
  const res = await httpClient.post(
    "auth/token/refresh/",
    { refresh: refreshToken },
    {
      headers: {
        Authorization: "",
      },
    }
  );
  return res.data;
};

export const forgotPasswordAPI = async (email: string) => {
  const res = await httpClient.post("users/password/reset/", { email });
  return res.data;
};

export const resetPasswordAPI = async (data: IResetPasswordAPIParams) => {
  const res = await httpClient.post("users/password/confirm/", data);
  return res.data;
};

export const activateAccountAPI = async (uid: string, token: string) => {
  const res = await httpClient.post("users/activation/", { uid, token });
  return res.data;
};

export const resendActivationEmailAPI = async (email: string) => {
  return await httpClient.post("users/activation/resend/", { email });
};

export const getUserDataAPI = async (config?: AxiosRequestConfig) => {
  return await httpClient.get("users/me/", config);
};
