import BrandLoader from "components/loaders/BrandLoader";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import React, { FC } from "react";

// @ts-ignore
const Auth: FC<React.PropsWithChildren> = ({ children }) => {
  const router = useRouter();
  const { status } = useSession({
    required: true,
    onUnauthenticated() {
      router.push("/");
    },
  });

  if (status === "loading") {
    return <BrandLoader />;
  }

  return children;
};

export default Auth;
