import {
  IApplyCourseAPIResponse,
  IGetLessonDetailAPIParams,
  IGetParticipantCourseListAPIParams,
  INote,
  ISendTaskAnswerAPIParams,
  ISendTestAnswersAPIParams,
  ITestResults,
} from "@types";
import httpClient from "services/http.service";

export const getLearningProgressAPI = async () => {
  const res = await httpClient.get("learning-progress/");
  return res.data;
};

export const getParticipantCourseListAPI = async (
  params?: IGetParticipantCourseListAPIParams
) => {
  const res = await httpClient.get("users/me/courses/", { params });
  return res.data;
};

export const getCertificateListAPI = async () => {
  const res = await httpClient.get("users/me/certificates/");
  return res.data;
};

export const applyCourseAPI = async (
  courseId: number
): Promise<IApplyCourseAPIResponse> => {
  const res = await httpClient.post(`courses/${courseId}/apply/`);
  return res.data;
};

export const completeLessonAPI = async (params: IGetLessonDetailAPIParams) => {
  const { courseId, moduleId, lessonId } = params;
  return await httpClient.get(
    `courses/${courseId}/modules/${moduleId}/lessons/${lessonId}/complete/`
  );
};

export const getUserCourseByCourseIdAPI = async (courseId: number) => {
  const res = await httpClient.get(`/users/me/courses/course/${courseId}/`);
  return res.data;
};

export const getTaskSolutionDetailByCriteriaIdAPI = async (
  criteriaId: number
) => {
  const res = await httpClient.post("/quiz-criteria-solutions/by_criteria/", {
    criteria_id: criteriaId,
  });
  return res.data;
};

export const sendTaskAnswerAPI = async (data: ISendTaskAnswerAPIParams) => {
  const res = await httpClient.post("/quiz-criteria-solutions/", data);
  return res.data;
};

export const sendTestAnswersAPI = async (data: ISendTestAnswersAPIParams) => {
  const { quizId, answers } = data;
  return await httpClient.post(`/quizzes/${quizId}/solve/`, answers);
};

export const getTestResultsAPI = async (
  quizId: number
): Promise<ITestResults> => {
  const res = await httpClient.get(`/quizzes/${quizId}/results/`);
  return res.data;
};

export const getLessonNoteListAPI = async (
  params: IGetLessonDetailAPIParams
) => {
  const { courseId, moduleId, lessonId } = params;
  const res = await httpClient.get<INote[]>(
    `/courses/${courseId}/modules/${moduleId}/lessons/${lessonId}/notes/`
  );
  return res.data;
};

export const createLessonNoteAPI = async (data: {
  lesson: number;
  text: string;
}) => {
  const res = await httpClient.post<INote>("/notes/", data);
  return res.data;
};

export const updateLessonNoteAPI = async (noteId: number, text: string) => {
  const res = await httpClient.patch<INote>(`/notes/${noteId}/`, { text });
  return res.data;
};

export const deleteLessonNoteAPI = async (noteId: number) => {
  return await httpClient.delete(`/notes/${noteId}/`);
};
