import { signOut } from "next-auth/react";
import axios, { AxiosRequestConfig } from "axios";
import { API_BASE_URL } from "utils/constants";
import { hasCookie, getCookie, setCookie, deleteCookie } from "cookies-next";
import { refreshTokenAPI } from "api/auth.api";

const logout = () => {
  deleteCookie("access_token");
  deleteCookie("refresh_token");
  signOut();
};

const httpClient = () => {
  const httpClientConfig: AxiosRequestConfig = {
    baseURL: API_BASE_URL,
    headers: {
      "Content-Type": "application/json",
    },
  };

  const instance = axios.create(httpClientConfig);

  instance.interceptors.request.use(async (request) => {
    if (hasCookie("access_token")) {
      // @ts-ignore
      request.headers.Authorization = `JWT ${getCookie("access_token")}`;
    }
    return request;
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalConfig = error.config;

      if (
        error?.response?.status === 401 &&
        !originalConfig._retry &&
        originalConfig.url !== "auth/login/"
      ) {
        originalConfig._retry = true;

        const refreshToken = getCookie("refresh_token") as string;

        return refreshTokenAPI(refreshToken)
          .then((token) => {
            setCookie("access_token", token.access);

            instance.defaults.headers.common[
              "Authorization"
            ] = `JWT ${token.access}`;

            return instance(originalConfig);
          })
          .catch((err) => {
            console.log(err);

            logout();
          });
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

export default httpClient();
