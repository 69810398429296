import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import authSlice from "./auth/slice";
import courseSlice from "./course/slice";
import lessonSlice from "./lesson/slice";
import { createWrapper } from "next-redux-wrapper";

const store = configureStore({
  reducer: {
    [authSlice.name]: authSlice.reducer,
    [courseSlice.name]: courseSlice.reducer,
    [lessonSlice.name]: lessonSlice.reducer,
  },
  devTools: process.env.NODE_ENV === "development",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["lesson/getLessonDetail/rejected"],
      },
    }),
});

const makeStore = () => store;

export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action
>;

export const storeWrapper = createWrapper<AppStore>(makeStore);
